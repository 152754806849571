* {
    font-family: Lato;
    margin: 0;
    padding: 0;
    outline:0;
}

html, #root {
    width: 100%;
    height: 300px;
}

#main {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

form {
    overflow: hidden;
    max-width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

input {
    width: 80% !important;
    height: unset !important;
}

.rccs {

    margin-bottom: 20px;
}